import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';

export function checkTTL(props) {
  const data = localStorage.getItem('appState');
  if (!data) {
      return null;
  }

  const item = JSON.parse(data);

  // If TTL has expired, logout
  if (Date.now() > item.user.ttl) {
      props.history.push("/Login/logout");
  }
}

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    {},
  );
}