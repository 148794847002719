import {Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';

import {
    ToastContainer,
} from 'react-toastify';

const Dashboard = lazy(() => import('../../Pages'));
const Login = lazy(() => import('../../Login'));


const AppMain = () => {

    return (
        <Fragment>

          
             {/* Dashboard */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Loading..
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pages" component={Dashboard}/>
            </Suspense>
            {/* Login*/}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                         Loading..
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/login" component={Login}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login/index"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;